import clsx from 'clsx';
import { useRef, DetailedHTMLProps, IframeHTMLAttributes, useEffect, useState } from 'react';

import styles from './iframe.module.scss';

type HTMLIframeElementProps = DetailedHTMLProps<IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>;

type IFrameAutoHeightProps = {
	autoHeight: true;
	height: number;
};

type IFrameFixedHeightProps = {
	autoHeight?: false;
	height?: number;
};

export type IFrameProps = HTMLIframeElementProps &
	(IFrameAutoHeightProps | IFrameFixedHeightProps) & {
		borderless?: boolean;
		fullWidth?: boolean;
	};

const IFrame = ({
	style,
	height,
	borderless = true,
	fullWidth = true,
	autoHeight = false,
	...rest
}: IFrameProps): React.JSX.Element => {
	const iframeRef = useRef<HTMLIFrameElement>(null);
	const [contentHeight, setContentHeight] = useState<number | undefined>(height);

	const heightStyles = height
		? {
				height: contentHeight
		  }
		: {};

	useEffect(() => {
		if (!autoHeight || !iframeRef?.current?.contentWindow) return;

		try {
			if (iframeRef?.current?.contentWindow?.document?.body?.scrollHeight) {
				setContentHeight(iframeRef.current.contentWindow.document.body.scrollHeight);
			}
		} catch {
			// eslint-disable-next-line no-console
			console.error("Can't access iframe content (CORS)");
		}
	}, [iframeRef?.current?.contentWindow]);

	return (
		<iframe
			{...rest}
			ref={iframeRef}
			className={clsx(borderless && styles.borderless, fullWidth && styles.fullWidth)}
			style={{ ...style, ...heightStyles }}
		/>
	);
};

export default IFrame;
