import { GetStaticPaths, GetStaticProps } from 'next';

import { PageInformationFieldsFragment, PageSlugFieldsFragment } from 'api/graphql-types';
import { getServerPageGet, getServerPageGetSlugs } from 'api/graphql-helper';
import client, { httpLink, setPreviewSecret } from 'api/apollo-client';

import Layout from 'modules/layout/layout';
import { ModuleBuilder } from 'modules/module-builder/module-builder';
import { Head } from 'modules/head/head';
import IntroHeader, { IModule } from 'modules/intro-header/intro-header';
import { BaseModule } from 'modules/base-module/base-module';

import { initialState as settingsInitialState } from 'redux/stores/settings';
import { getStrapiParametersFromContext } from 'utils/strapi-parameters';
import { IBasePageProps } from 'types/page';
import usePageProtection, { IUsePageProtectionVariables } from 'utils/hooks/use-page-protection';
import PasswordLayout from 'modules/password-layout';
import IFrame from 'components/iframe/iframe';

interface ICatchAllPageProps extends IBasePageProps {
	page?: PageSlugFieldsFragment;
	pageInformation?: PageInformationFieldsFragment;
	id?: string;
	variables?: IUsePageProtectionVariables;
}

const CatchAllPage = (props: ICatchAllPageProps): React.JSX.Element => {
	const { page, PasswordModule } = usePageProtection({
		pageData: props.page || null,
		pageQuery: () => getServerPageGet({ variables: props.variables }, client)
	});

	return (
		<Layout
			contentId={props?.id}
			typeName={page?.__typename}
			navbar={props?.pageInformation?.navbar}
			footer={props?.pageInformation?.footer}
		>
			<Head seoModule={page?.seo} seoParent={props?.pageInformation?.seo} />
			{page && page.__typename === 'Page' ? (
				<>
					<BaseModule
						defaultModuleOptions={{
							backgroundColor: page.header?.backgroundColor || 'white',
							padding: 'none'
						}}
					>
						<IntroHeader module={page.header as IModule} fullPath={page.fullSlug || '/'} />
					</BaseModule>
					<ModuleBuilder modules={page.modules || []} fullPath={page.fullSlug || '/'} />
				</>
			) : (
				<PasswordLayout locale={props.variables?.locale} slug={props.variables?.slug}>
					<PasswordModule />
				</PasswordLayout>
			)}
			{page && page.fullPath === 'vraag' && (
				<>
					<div>
						<iframe
							style={{ width: '100%', height: '2350px', overflow: 'hidden' }}
							src="https://gelderland.custhelp.com/app/ask"
							seamless={true}
							scrolling="no"
							frameBorder="0"
						></iframe>
					</div>
				</>
			)}
			{page && page.fullPath === 'melding' && (
				<>
					<div>
						<IFrame src="https://gelderland.custhelp.com/app/melding" height={4000} seamless autoHeight />
					</div>
				</>
			)}
		</Layout>
	);
};

export const getStaticPaths: GetStaticPaths = async (ctx) => {
	const pageSlugsResult = await getServerPageGetSlugs({}, client);
	const slugResult = pageSlugsResult.props.data?.findAllPaths;

	const paths = slugResult?.map((slugObject) => ({
		params: {
			slug: slugObject?.fullSlug?.split('/')
		},
		locale: slugObject?.locale || ctx.defaultLocale
	}));

	return {
		paths: paths || [],
		fallback: 'blocking'
	};
};

export const getStaticProps: GetStaticProps = async (ctx) => {
	try {
		const { locale, publicationState } = getStrapiParametersFromContext(ctx);
		const slug = ctx.params?.slug as string[];
		const fullSlug = slug.join('/');

		if (fullSlug.includes('assets')) {
			return {
				notFound: true
			};
		}

		const variables = {
			slug: fullSlug,
			locale,
			publicationState
		};

		if (publicationState === 'PREVIEW') {
			client.setLink(setPreviewSecret(process.env.STRAPI_PREVIEW_SECRET as string).concat(httpLink));
		}

		const pageResult = await getServerPageGet({ variables }, client);
		const { pageBySlug, pageInformation } = pageResult?.props?.data ?? {};

		const initialReduxState = {
			settings: {
				...settingsInitialState,
				publicationState,
				contentType: 'page',
				contentTypeId: pageBySlug?.data?.id || null
			}
		};

		if (pageBySlug === null) {
			return {
				props: {
					variables,
					pageInformation: pageInformation?.data?.attributes,
					page: null,
					initialReduxState
				},
				revalidate: Number(process.env.REVALIDATE_TIME_IN_SECONDS)
			};
		}

		if (!pageBySlug?.data?.attributes) {
			return {
				notFound: true
			};
		}

		return {
			props: {
				key: pageBySlug.data?.id,
				page: pageBySlug.data?.attributes,
				pageInformation: pageInformation?.data?.attributes,
				id: pageBySlug.data?.id,
				initialReduxState
			},
			revalidate: Number(process.env.REVALIDATE_TIME_IN_SECONDS)
		};
	} catch (error: any) {
		// eslint-disable-next-line no-console
		console.error('Error in page detail', error);

		return {
			notFound: true
		};
	}
};

export default CatchAllPage;
